
/*theme color #2E7B8C;*/

@font-face {
    font-family: "Raleway-400";
    src: url("/fonts/Raleway/Raleway-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Raleway-500";
    src: url("/fonts/Raleway/Raleway-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Raleway-600";
    src: url("/fonts/Raleway/Raleway-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Oswald-700";
    src: url("/fonts/Oswald/Oswald-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Oswald-600";
    src: url("/fonts/Oswald/Oswald-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Oswald-500";
    src: url("/fonts/Oswald/Oswald-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Oswald-400";
    src: url('/fonts/Oswald/Oswald-Regular.ttf') format("truetype");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}
body, html{
    overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
    color: black;
}
h1, h2{
    font-family: "Oswald-700";
    font-size: 48px;
    line-height: 58px;
    color: black;
    text-align: center;
    margin-bottom: 50px;
}
h3{
    font-family: "Oswald-700";
    font-size: 36px;
    line-height: 120%;
    color: black;
    margin-bottom: 50px;
}
h4{
    font-family: "Oswald-700";
    font-size: 30px;
    line-height: 36px;
}
h5{
    font-family: "Oswald-600";
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 50px;
}
h6{
    font-family: "Oswald-500";
    font-size: 18px;
    line-height: 24px;
}
form textarea, form input,
p, a, li, .content th, .content td, 
body form textarea.ant-input{
    font-family: "Raleway-400";
    font-size: 16px;
    line-height: 24px;
    color: black;
}
a{
    color: #2E7B8C;
}
a:hover{
    color: #FF9776;
    transition: color 0s;
    /*text-decoration: underline;*/
}
.title_head{
    max-width: 625px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.container{
    margin-left:auto;
    margin-right:auto;
    position: relative;
}
.box{
    padding-top: 80px;
    padding-bottom: 80px;
}
.dusk{
    background-color: #00000008;
}
@media (max-width: 767px){
    .container{
        padding-right:15px;
        padding-left:15px
    }
}
@media (min-width: 768px){
    .container{
        width:720px;
    }
}
@media (min-width: 992px){
    .container{
        width:960px;
    }
}
@media (min-width: 1200px){
    .container{
        width:1170px;
    }
}
@media (min-width: 1400px){
    .container{
        /*width:1340px;*/
    }
}
form .ant-btn, 
form input[type="submit"],
.button{
    height: 38px;
    width: 230px;
    border-radius: 30px;   
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Raleway-600";
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    margin: 0;
    border: none;
}
form .ant-btn, 
form input[type="submit"],
.button.blue{
    background-color: #2E7B8C!important;
}
.ant-btn.red,
.button.red{
    background-color: #FF9776!important;
}
.ant-btn.button_big, 
form input[type="submit"],
.button_big{
    width: 300px;
    height: 50px;
    font-size: 16px;
}
.button_big:hover{
    color: white;
}
.button_big img{
    margin-left: 14px;
}
.button_flag{
    height: 50px;
    width: 270px;    
    background: #FFFFFF;
    border: 1px solid #FF9776;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Raleway-500";
    font-size: 18px;
    line-height: 120%;
    color: #000000;
    transition: color 0s;
}
.button_flag:hover{
    background-color: #FF9776;
    color: white;
}
.button_flag img{
    margin-right: 10px;
}
.buttons_flag {
    display: flex;
    justify-content: space-between;
    padding: 0;
    list-style-type: none;
    margin-bottom: 40px;
}
.flex{
    display: flex;
}

/*form ======================================*/

body form textarea.ant-input{
    height: 105px;
    padding: 18px 0;
}
form .ant-form-item{
    margin-bottom: 0;
}
body form textarea.ant-input,
body form input.ant-input {
    color: black;
    font-size: 16px;
    outline: none;
    box-shadow: none!important;
    /*border: none;*/
}

form{
    width: 370px;
    display: flex;
    flex-direction: column;    
}
form textarea.ant-input,
form textarea,
form input[type="text"],
form input[type="phone"]{
    height: 60px;
    border: none;
    outline: none;
    border-bottom: 1px solid #2E7B8C;
    padding: 18px 0;
}
form textarea{
    height: 100px;
    resize: none;
}
form textarea::placeholder,
form input::placeholder{
    font-family: "Raleway-400";
    font-size: 16px;
    line-height: 24px;
    color: #999999;    
}
form .ant-btn, 
form input[type="submit"]{
    margin-top: 30px;
}
form textarea::-webkit-input-placeholder,
form input::-webkit-input-placeholder {
    color: #999999!important;
} 
form p.done{
    text-align: center;
}

/*breadcrumb =============================================*/

.breadcrumb{
    display: flex;
    align-items: center;
    margin: 40px 0;
    flex-flow: wrap;
}
.breadcrumb a,
.breadcrumb p{
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
}
.breadcrumb>div{
    display: flex;
    align-items: center;
    margin-right: 7px;
}
.breadcrumb>div:after{
    content: "/";
    margin-left: 6px;
}
.breadcrumb>div:last-child:after{
    display: none;
}

/*blockquote ===============================*/

blockquote{
    position: relative;
    padding-left: 20px;
}
blockquote:before{
    content: "";
    background-color: #FF9776;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 0;
}
blockquote strong{
    font-family: "Raleway-600";
    font-weight: initial;
    font-size: 16px;
    line-height: 24px;
    color: #FF9776;
}


/*ant-pagination ============================*/

.ant-pagination{
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}
.ant-pagination a,
.ant-pagination p{
    font-family: "Raleway-500";
    font-size: 18px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0; 
}
.ant-pagination .ant-pagination-item-active p{
    color: #2E7B8C;
    font-size: 24px; 
}
.ant-pagination img{
    margin-top: 5px;
}
.ant-pagination .ant-pagination-item {
    border: none;
}
.ant-pagination .ant-pagination-jump-next a img,
.ant-pagination .ant-pagination-jump-prev a img{
    display: none;
}
.ant-pagination .ant-pagination-jump-next a:hover img,
.ant-pagination .ant-pagination-jump-prev a:hover img{
    display: block;
}
.ant-pagination .ant-pagination-jump-next a:hover p,
.ant-pagination .ant-pagination-jump-prev a:hover p{
    display: none;
}
.ant-pagination .ant-pagination-disabled{
    pointer-events: none;
}
.ant-select-selector{
    box-shadow: none!important;
}

/*content ================================*/

.content h2, .content h3, .content h4, .content h5, .content h6 {
    margin-bottom: 20px;
}
.content p, .content ul{
    margin-bottom: 30px;
}
.content ul{
    padding-left: 38px;
    list-style-type: none;
}
.content ul li{
    margin-bottom: 15px;
    position: relative;
}
.content ul li:after{
    content: "";
    background-image: url(/img/list-style.svg);
    position: absolute;
    width: 21px;
    height: 9px;
    left: -37px;
    top: 8px;
}
.content .wp-block-gallery{
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    flex-flow: wrap;
}
.content .wp-block-gallery .wp-block-image{
    margin: 0;
    width: 270px;
    /*height: 150px;*/
}
.content .wp-block-gallery .wp-block-image img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.content table{
    width: 100%;
    margin-bottom: 30px;
}
.content table, .content th, .content td {
    border: 1px solid black;
    border-spacing: 0;
    border-collapse: collapse;
    padding: 10px;
}
.content figure {
    margin-bottom: 30px;
}
.content figure.alignright {
    float: right;
    margin-left: 30px;
}
.content figure.alignleft  {
    float: left;
    margin-right: 30px;
}
.default_page h1 {
    margin-top: 80px;
}
.content figure img{
    width: 100%;
    height: initial;
}

/*default_page ========================*/

.default_page .messengers{
    padding-top: 0px;
}
.employers img{
    width: 100%;
}


/*header ===========================*/

.header .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.header .logo{
    display: flex;
    align-items: center;
    min-width: 295px;
}
.header .logo img{
    margin-right: 5px;
}
.header .logo p{
    margin: 0;
}
.header .logo p{
    font-size: 13px;
    line-height: 21px;
}
.header .logo p.logo_text_big{
    font-family: "Oswald-700";
    font-size: 28px;
    line-height: 41px;
    color: #FF9776;
}
.header .city p{
    font-size: 14px;
    line-height: 24px;
    color: #555555;
    margin: 0;
}
.header .city .ant-select{
    margin-left: 20px;
    margin-top: -5px;
}
.header .city p img{
    margin-right: 6px;
}
.ant-select-dropdown.city_dropdown {
    min-width: 175px!important;
    background-color: #FF9776!important;    
    border-radius: 0;
}
/*.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: rgba(0, 0, 0, 0.85);
    font-weight: initial;
    background-color: transparent;
    color: white;
    font-size: 18px;
    /* width: 200px; */
/*}*/
.city_dropdown  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-dropdown.city_dropdown .ant-select-dropdown-menu-item {
    font-family: "Raleway-500";
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 9px 25px;
    background-color: transparent;
    font-weight: initial;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover{
    text-decoration: underline;
}
.ant-select-dropdown.city_dropdown .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown.city_dropdown .ant-select-dropdown-menu-item-selected {
    background-color: #FF9776;
    font-weight: initial;
}
.ant-select-dropdown.city_dropdown .ant-select-dropdown-menu-item:hover{
    text-decoration: underline;
}
.ant-select-dropdown.language_dropdown {
    width: 55px!important;
}
.header .ant-select .ant-select-selection {
    border: none;
    box-shadow: none;
}
.header .language .ant-select-selection-item a,
.header .language .ant-select-selection-selected-value a{
    pointer-events: none;
}
.header .city .ant-select-selection-item,
.header .ant-select .ant-select-selection-selected-value{
    font-family: "Raleway-400";
    font-size: 14px;
    line-height: 24px;
    color: #2E7B8C;
    text-decoration: underline;
}
.header .city .ant-select-selector{
    box-shadow: none!important;
}
.header .ant-select .ant-select-arrow{
    background-image: url('/img/arrow.png');
    width: 12px;
    height: 8px;
    right: 4px;
    top: 45%;
}
.header .ant-select .ant-select-arrow i{
    display: none;
}
.header .language .ant-select .ant-select-arrow{
    top: 60%;
}
.header .button.blue{
    margin-bottom: 10px;
}
.header ul.phones{
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 56px;
    grid-row-gap: 32px;
    margin: 0;
    padding: 0;  
    position: relative;  
}
.header ul.phones:after{
    content: "";
    background-image: url(/img/phone.svg);
    position: absolute;
    width: 25px;
    height: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.header ul li{
    position: relative;
}
.header ul.phones li:nth-child(1):after,
.header ul.phones li:nth-child(2):after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #BDBDBD;
    bottom: -17px;
    left: 0;
}
.header ul.phones a{
    font-family: "Raleway-400";
    font-size: 16px;
    line-height: 24px;
    color: black;
}
.header ul.phones a span{
    font-family: "Raleway-500";
    font-size: 16px;
    line-height: 24px;
    color: #2E7B8C;
}
.header .bottom {
    background-color: #2E7B8C;
    height: 50px;
    display: flex;
    align-items: center;
}
.header .menu{
    margin: 0 -15px; 
}
.header .menu a{
    font-family: "Raleway-500";
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 9px 15px;
    display: block;
}
.header .menu li:hover>a{
    text-decoration: underline;
}
.header .menu ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 1;
}
.header .menu>ul{
    display: flex;
    justify-content: space-between;
}
.header .menu>ul>li:hover:after{
    content: "";
    width: calc(100% + 50px);
    height: 28px;
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 1;
}
.header .menu ul li.has_child:before{
    content: "";
    background-image: url(/img/arrow-2.svg);
    width: 12px;
    height: 8px;
    right: -7px;
    top: 45%;
    position: absolute;
}
.header .menu ul ul li.has_child:before{
    transform: rotate(-90deg);
    right: 15px;
}
.header .menu ul ul{
    display: none;
    position: absolute;
    background-color: #2e7b8c;
    top: 53px;
    padding: 8px 0px 8px 10px;
    width: max-content;
}
.header .menu ul li:hover>ul{
    display: block;
}
.header .menu ul ul li{
    padding-right: 30px;
}
.header .menu ul ul li:hover:after{
    content: "";
    width: 10px;
    height: 45px;
    position: absolute;
    right: -10px;
    top: 0;
    z-index: 1;
}
.header .menu ul ul ul{
    left: calc(100% + 10px);
    top: -8px;    
}
.header .phones_dropdown{
    height: 0;
    transition: all .2s;
    overflow: hidden;    
    position: absolute;
    width: 100%;
    background-color: white;
}
.header .phones_dropdown.active{
    height: 141px;
    z-index: 1;
}
.header .phones_dropdown>div{
    display: flex;
    justify-content: space-between;
    padding: 35px 0 20px;
}
.header .menu_dropdown {
    background-color: #2E7B8C;
    padding: 32px 20px;
    height: calc(100vh - 90px);
    position: absolute;
    width: 100%;
    left: -100%;
    transition: all .2s;
    z-index: 1;
}
.header .menu_dropdown.active{
    left: 0;
}
.header .menu_dropdown:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    top: -100px;
    left: 0;
    background: #2E7B8C;
    opacity: 0.3;
}
.header .menu_dropdown .menu>ul {
    flex-direction: column;
}
.header .phone_menu_buttons{
    z-index: 2;
}
.header .phone_menu_buttons img.ico_phone {
    margin-right: 36px;
}
.header .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
}

/*slider ================================*/

.slider .ant-carousel .slick-slide img {
    width: 100%;
}
.slider .ant-carousel .slick-dots li {
    margin: 0 20px;
    width: initial;
}
.slider .ant-carousel .slick-dots li button {
    opacity: 1;
    width: 178px;
    height: 8px;
    background: #BDBDBD;
    border-radius: 8px;
}
.slider .ant-carousel .slick-dots li{
    height: 8px;
}
.slider .ant-carousel .slick-dots li.slick-active button {
    background: #FF9776;
}

.slider .ant-carousel .slick-dots-bottom {
    bottom: 45px;
    z-index: 1;
}
.slider .slick-dots li button:before{
    display: none;
}

.slider .slider_text{
    position: absolute;
    width: 100%;
}
.slider .slider_text p.slider_big_text{
    font-family: "Oswald-700";
    font-size: 64px;
    line-height: 58px;
    color: white;
    margin-bottom: 15px;
}
.slider .slider_text p.slider_small_text{
    font-family: "Oswald-400";
    font-size: 36px;
    line-height: 58px;
    color: white;
    margin-bottom: 0;
}
.slider .slid {
    display: flex!important;
    align-items: center;
}
.slider .ant-carousel .slick-dots {
     margin-right: initial;
     margin-left: initial;
 }

/*job_in_europe =====================================*/

/*check-dubble.svg*/
.job_in_europe ul{
    list-style-type: none;
    margin-left: 57px;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 60px;
}
.job_in_europe ul li{
    font-size: 20px;
    line-height: 24px;
    position: relative;
}
.job_in_europe ul li span{
    font-family: "Raleway-500";

}
ul.list_style_type_double_check{
    list-style-type: none;
    margin-left: 57px;
    padding: 0;
}
ul.list_style_type_double_check li{
    position: relative;
}
ul.list_style_type_double_check li:before{
    content: "";
    background-image: url(/img/check-dubble.svg);
    width: 40px;
    height: 40px;
    position: absolute;
    left: -60px;
}


/*additional_services =================================*/

.additional_services{
    margin-top: 40px;
}
.additional_services>p{
    font-family: "Oswald-600";
    font-size: 20px;
    line-height: 24px;
}
.additional_services ul{
    width: 270px;
    list-style-type: none;
    padding: 0;    
}
.additional_services ul li{
    margin-bottom: 20px;
}
.additional_services .service{
    width: 270px;
    height: 162px;
    background: #2E7B8C;
}
.additional_services .service p{
    font-family: "Oswald-600";
    color: #FFFFFF;
    font-size: 20px;
    line-height: 38px;
    padding-top: 12px;
}
.additional_services .service_1 p span{
    font-size: 26px;
}
.additional_services .service_1 p span span{
    color: #FF9776;
}

/*vacancy ==============================================*/

.vacancy {
    margin-left: 30px;
}
.vacancy .panel{
    display: flex;
    margin-bottom: 15px;
}
.vacancy .panel>div{
    width: 100%;
    margin-left: 50px;
}
.vacancy .panel .bottom{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.vacancy .panel h1{
    font-size: 28px;
    line-height: 34px;
    text-align: initial;
    margin-bottom: 20px;
}
.price_block p.place_of_work {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 24px;
}
.price_block p.place_of_work img{
    margin-right: 7px;
}
.price_block p.price{
    font-family: "Raleway-400";
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 6px;
}
.price_block p.price span,
.price_block p.price strong{
    font-family: "Raleway-500";
    font-weight: initial;
}
.price_block p.price span{
    font-size: 28px;
    color: #FF9776;
}





/*comments =================================*/

.comments .top,
.comments .body{
    border-bottom: 1px solid #F2F2F2;
    margin-bottom: 25px;
}
.comments h4{
    margin-bottom: 40px;
}
.comments p.login_with_google{
    text-align: right;
    margin-bottom: 20px;
}
.comments p.login_with_google img{
    margin-left: 17px;
}
.comments p.login_with_google span{
    color: #2E7B8C;
    cursor: pointer;
}
.comments p.login_with_google span:hover{
    text-decoration: underline;
}
.comments .textarea {
    display: flex;
    align-items: flex-start;
}
.comments .textarea img{
    margin-right: 20px;
}
.comments .textarea .ant-form-item {
    width: 100%;
}
.comments form{
    margin-bottom: 24px;
}
.comments .body button,
.comments .body .button {
    margin-left: 70px;
}
.comments .ant-input {
    border: 1px solid #d9d9d9;
    padding: 18px;
}
.comments .number_of_comments img{
    margin-right: 13px;
}
.comments .list ul{
    list-style-type: none;
}
.comments .list ul{
    margin: 0;
    padding: 0;
}
.comments .list ul li {
    display: flex;
    align-items: flex-start;
    margin-top: 35px;
}
.comments .list ul li>div{
    margin-left: 15px;
}
.comments .list .comment_description {
    display: flex;
    align-items: center;
    margin: 15px 0 23px 0;
}
.comments .list .comment_description p{
    margin: 0;
}
.comments .list .comment_description p.name{
    color: #2E7B8C;
}
.comments .list .comment_description img{
    margin: 0 8px 0 15px;
}
.comments .list .comment_description p.date{
    color: #BDBDBD;
    font-size: 14px;
}
.comments .list .comment_callback {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.comments .list .comment_callback:hover p{
    text-decoration: underline;
}
.comments .list .comment_callback p{
    margin: 0 0 0 12px;
    font-size: 14px;
    color: #555555;
}
.comments .list p.comment_item{
    margin-bottom: 22px;
}
.comments .body form{
    width: 100%;
}

/*footer ==========================================*/

footer p,
footer a{
    color: white;
}
footer .top{
    background-color: #2E7B8C;
}
footer .top .container {
    display: flex;
    justify-content: space-between;
}
footer p.footer_title{
    font-family: "Oswald-700";
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
}
.phones{
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 10px;
    margin: 0;
    padding: 0;
    position: relative;
    padding-left: 45px;
    margin-bottom: 25px;
}
footer .phones:before{
    content: "";
    background-image: url(/img/phone-3.svg);
    width: 25px;
    height: 25px;
    position: absolute;
}
footer .buttons{
    display: flex;
}
footer .buttons .button:not(:first-child){
    margin-left: 70px;
}
footer .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.address {
    display: flex;
    align-items: flex-start;
    margin-bottom: 25px;
}
.address div{
    margin-left: 20px;
}
.address div img{
    margin-bottom: 4px;
}
.address p{
    margin-bottom: 0;
}
.mail a{
    margin-left: 20px;
}
.mail{
    margin-bottom: 25px;
}
footer .bottom{
    background-color: #222222;
    padding: 45px 0;
}
footer .bottom a{
    font-size: 14px;
    line-height: 28px;
}
footer p.title{
    font-family: "Oswald-400";
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 25px;
}
footer .bottom ul{
    padding: 0;
    list-style-type: none;
}
footer .menu_bottom {
    display: grid;
    grid-template-columns: 1fr 1.3fr 1fr 0fr;
    margin-bottom: 50px;
}
ul.messengers {
    display: flex;    
    padding: 0;
    margin: 0;
    list-style-type: none;
}
ul.messengers li:not(:last-child){
    margin-right: 10px;
}
footer .menu_bottom .visa ul{
    display: grid;
    grid-template-columns: 1fr 1.5fr; 
}
footer .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
footer .copyright p{
    margin: 0;
}
footer .copyright>div{
    display: flex;    
    align-items: center;
}
footer .copyright ul{
    display: flex;
    margin: 0;
    margin-left: 25px;
}
footer .copyright ul li{
    margin-right: 10px;
}


/* vacancies filters ==============================*/

.filters{
    width: 270px;   
}
.checkbox{
    display: block;
    margin-bottom: 10px;

                
}
.checkbox label{
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 18px;
    line-height: 25px;              
}
.checkbox label:before{
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    border: 1px solid #BDBDBD;
    border-radius: 0px;
}   
.checkbox input[type="checkbox"]{
    display: none;
}
.checkbox input[type="checkbox"]:checked+label:after{
    content: "";
    background-image: url(/img/check.svg);
    width: 18px;
    height: 14px;
    position: absolute;
    left: 5px;
    top: 0;
}
.filters ul{
    padding: 0;
    list-style-type: none;
}
.filters ul li{
    margin-bottom: 15px;
}
.filters .bottom>ul>li{
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 20px;
    margin-bottom: 18px;
}
.filters .bottom>ul>li>p,
.filters .bottom>ul>li>label{
    font-family: "Raleway-600";
    font-size: 18px;
    line-height: 120%;
    pointer-events: none;
    margin-bottom: 23px;
    display: table;
    padding-left: 0;
}
.filters .bottom>ul>li>label:after,
.filters .bottom>ul>li>label:before{
    display: none;
}
.filters label{
    font-family: "Raleway-400";
    font-size: 16px;
    line-height: 120%;
    display: flex;
}
.filters label p{
    font-family: "Raleway-400";
    font-size: 16px;
    /*line-height: 120%;*/
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.filters .bottom>ul>li>ul{
    max-height: 258px;
    overflow-y: auto;
}
.filters .bottom>ul>li>ul::-webkit-scrollbar {
    width: 4px;
}
.filters .bottom>ul>li>ul::-webkit-scrollbar-track {
    background: #F2F2F2;
    /*border: 1px solid transparent;*/
    background-clip: content-box;
}
.filters .bottom>ul>li>ul::-webkit-scrollbar-thumb {
    background: #2E7B8C;
    border: 1px solid rgb(45, 92, 136);
}
.filters .bottom>ul>li>ul>li:last-child{
    margin-bottom: 0;    
}
.filters .ant-input-number{
    width: 92px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 30px;    
    box-shadow: none;
}
.filters .ant-input-number .ant-input-number-handler-wrap{
    display: none;
}
.filters .filter_slider li.filter_slider_inputs{
    display: flex;
    align-items: center; 
    justify-content: space-between;
    color: #555555;
    font-size: 15px; 
}
.filters .filter_slider input.ant-input-number-input{
    font-size: 16px;
    height: 35px;
    text-align: center;
    color: black;
}
.filters .filter_slider .ant-slider-handle{
    width: 18px;
    height: 18px;
    background: #2E7B8C;    
    border: none;
    margin-top: -8px;
    box-shadow: none;
}
.filters .filter_slider .ant-slider-rail {
    background: #C4C4C4;
    height: 2px;
}
.filters .filter_slider .ant-slider-track{
    background: #2E7B8C;
    height: 2px;
}
.filters .filter_slider .ant-slider {
    margin: 22px 10px 5px 10px;
}
.ant-tooltip-content{
    display: none;
}
.filter_open{
    display: none;
}
.item_id_82,
.item_id_84{
    display: none;
}

/*vacancies =========================================*/

.vacancies{
    display: flex;    
}
.vacancies ul.items{
    padding-left: 30px;
    list-style-type: none;
    margin-bottom: 50px;
}
.vacancies ul.items li{
    position: relative;
    padding: 5px;
    border: 1px solid #F2F2F2;
    margin-bottom: 30px;
}
.vacancies ul.items li .thumbnail{
    margin-right: 40px;
}
.vacancies ul.items li img.hot{
    position: absolute;
    top: 0;
    left: 0;
}
.vacancies ul.items li>a{
    display: flex;
}
.vacancies ul.items li p.title{
    font-family: "Oswald-700";
    font-size: 28px;
    line-height: 34px; 
    margin: 20px 0 15px 0;
}
.vacancies ul.items li img.flag{
    position: absolute;
    bottom: 10px;
    right: 10px;
}

/*hot_vacancies ====================================*/

.hot_vacancies ul.items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    padding: 0;
    list-style-type: none;
    margin-bottom: 40px;
}
.hot_vacancies .items li{
    display: flex;
    background-color: white;
}
.hot_vacancies .items .panel {
    padding: 20px 30px;
}
.hot_vacancies .items .panel .title{
    font-family: "Oswald-700";
    font-size: 21px;
    line-height: 120%;    
}
.hot_vacancies .items .panel .price_block {
    margin-bottom: 20px;
}
.hot_vacancies .button_big{
    margin: 0 auto;
}


/*form container ===================================*/

.form .top{
    display: flex;
    justify-content: space-between;
}
.form .description {
    max-width: 265px;
}
.form .description p{
    font-family: "Raleway-500";
    line-height: 24px;
    margin: 0;
}
.form .description p.bold{
    font-family: "Raleway-600";
    line-height: 24px;  
}
.form .left{
    display: flex;
    align-items: center;    
}


/*five_steps ========================================*/

.five_steps ul{
    /*list-style-type: decimal-leading-zero;*/
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
}
.five_steps ul li{
    position: relative;
    padding-right: 25px;
}
.five_steps ul li:before{
    content: "";
    background-image: url(/img/check-red.svg);
    width: 13px;
    height: 11px;
    position: absolute;
    top: -23px;
    left: 7px;
}
.five_steps ul li span{
    position: absolute;
    font-family: "Oswald-700";
    font-size: 96px;
    line-height: 120%;
    color: #FFEFEA;
    z-index: -1;
    top: -28px;
    right: 41px;
} 
.five_steps .title_head{
    margin-bottom: 85px;
}

/*partners =======================================*/

.partners .item{
    padding: 0 10px;
}
.partners .item img{
    margin: 0 auto;
}
.partners .separate{
    height: 80px;
    display: block;
}

/*reviews ========================================*/

.reviews .description{
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #555555;
    margin-bottom: 50px;
}
.reviews .separate {
    height: 80px;
    display: block;
}
.reviews video {
    margin: 0 auto;
    display: table;
}

/*blog ===============================================*/

.blog ul.items{
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
/*    display: flex;
    flex-flow: wrap;*/
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
}
.blog ul.items li{
    background-color: white;
    width: 100%;
    max-width: 370px;
}
.blog ul.items li picture img{
    width: 100%;
}
.blog ul.items li p.title{
    font-family: "Oswald-700";
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 20px;
}
.blog ul.items li .description p{
    margin-bottom: 0;
}
.blog ul.items li .bottom{
    padding: 20px;
}
.blog .button{
    margin: 0 auto;
}
.blog picture {
    min-height: 225px;
    display: block;
}

/*Popups ===============================================*/

.popup {
    position: fixed;
    top: 110px;
    left: 50%;
    /*min-width: 400px;*/
    min-height: 200px;
    background: white;
    transform: translate(-50%, 0%);
    z-index: 101;
    animation: popup 0.1s;
    overflow: auto;
    max-height: calc(100vh - 110px);
}
.mask {
    background: #2E7B8C;
    background-color: black;
    opacity: 0.3;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    animation: popup 0.1s;
}
@keyframes popup {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}
.popup_callback{
    width: 1170px;
    display: flex;
}
.close {
    position: absolute;
    background-image: url(/img/close-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 40px;
    height: 40px;
    background-color: #2E7B8C;
    cursor: pointer;
    right: 0;
    top: 0;
}
.popup_callback .left,
.popup_callback .right{
    width: 50%;
    padding: 30px;
}
.popup_callback .left{
    background: #2E7B8C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popup_callback .left p.title{
    font-family: "Oswald-600";
    font-size: 30px;
    line-height: 44px;
    color: #FFFFFF; 
}
.popup_callback .left .phones {
    padding: 0;
    margin-bottom: 30px;
}
.popup_callback .left .phones a{
    color: white;
}
.popup_callback .right p.title{
    font-family: "Oswald-600";
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #000000;    
}
.popup_callback .right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}
.popup_callback form .ant-btn, 
.popup_callback form input[type="submit"] {
    margin-left: auto;
    margin-right: auto;
}

/*post ==============================================*/

.post picture {
    float: left;
    max-width: 560px;
    width: 100%;
    margin: 0 40px 40px 0;
    box-shadow: 10px 10px 0px #2e7b8c;
}
.post h1{
    font-size: 36px;
    line-height: 120%;
    text-align: initial;
    margin-bottom: 30px;
}
.post .calendar img{
    margin-right: 10px;
}
.post p.calendar{
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 120%;
    color: #BDBDBD;
}
.post picture img{
    width: 100%;
}

/*messengers_buttons =========================================*/

ul.messengers_buttons{
    list-style-type: none;
    margin: 0;
    padding: 0;
    grid-gap: 30px;    
    display: flex;
    flex-flow: wrap;
    justify-content: center;
}
ul.messengers_buttons li{
    width: 270px;
    height: 50px;
    border: 1px solid #2E7B8C;
    border-radius: 30px;    
}
ul.messengers_buttons li a{
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    color: black; 
    font-family: "Raleway-500";
}
ul.messengers_buttons li a img{
    margin: 0 20px;
}

/*additional_services_banners ===================================*/

.additional_services_banners .additional_services ul{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
}

/*visas ===========================================*/

.visas {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    list-style-type: none;
    grid-gap: 30px;
    padding: 0;
}
.visas li {
    width: 270px;
    border: 1px solid #FF9776;
    border-radius: 15px;
    padding: 30px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;
}
.visas li .description{
    padding: 0px 30px; 
}
.visas li .title{
    display: flex;
    align-items: center;
}
.visas li .title img{
    margin-right: 10px;
}

/*calling_you_receive ================================*/

.calling_you_receive {
    margin-bottom: 50px;
}
.calling_you_receive h5 {
    margin-bottom: 30px;
}
.calling_you_receive ul{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;    
}

/*comprehensive_assistance ================================*/

.comprehensive_assistance ul li{
    font-family: "Raleway-500";
    font-size: 20px;
    line-height: 24px; 
    margin-bottom: 10px;   
}
.comprehensive_assistance .container{
    display: flex;
}
.comprehensive_assistance ul{
    width: 50%;
    margin-left: 86px;    
}



/*responsive ======================================*/

@media (min-width: 1200px) {

}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (min-width: 576px) and (max-width: 767px) {

}
@media (min-width: 321px) and (max-width: 575px) {

}
@media (max-width: 320px) {

}

@media (max-width: 1199px) {
    #root {
        padding-top: 100px;
    }
    .header {
        position: fixed;
        z-index: 1;
        background-color: white;
        width: 100%;
        top: 0;
        border-bottom: 1px solid #2E7B8C;
        z-index: 2;
    }
    .header .top{
        height: 100px;
    }
    .header .menu ul ul {
        top: 0;
        padding: 0 19px;
        position: relative;
    }
    .header .menu ul ul ul {
        left: 0;
        top: 0;
    }
    .header .menu ul li.has_child:before {
        top: 15px;
        transform: rotate(-90deg);
        transition: all .2s;
    }
    .header .menu ul li.has_child:hover:before{
        transform: rotate(0deg);
    }
    .header .menu>ul>li:hover:after{
        display: none;
    }
    .header .menu ul li:hover>ul {
        width: 100%;
        padding: 0 0 0 20px;
    }
    .header .menu ul ul li.has_child:before{
        right: -7px;
    }
    .header .menu ul ul li {
        padding-right: 0;
    }
    .header .menu a {
        display: table;
        padding: 9px 0px;
    }
    .header .menu {
        margin: 0;
        border-bottom: 1px solid white;
        max-width: 320px;    
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
    footer .top .container {
        flex-direction: column;
        align-items: center;
    }
    footer .left {
        padding: 80px 0;
    }
    .buttons_flag {
        flex-flow: wrap;
        justify-content: space-around;
    }
    .buttons_flag li{
        margin-bottom: 20px;
    }
    .filters {
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        z-index: 2;
        width: 100%;
        transition: all .2s;
        left: -100%;
        padding: 20px;
    }
    .filters.active{
        left: 0;
    }
    .filter_open {
        display: block;
        position: fixed;
        left: 0;
        top: 200px;
        z-index: 1;
    }
    .filters .top{
        position: relative;
        height: 50px;
    }
    .filters .top>p{
        font-family: "Oswald-600";
        font-size: 20px;
        line-height: 120%;  
        text-align: center;      
    }
    .filter_close {
        position: absolute;
        right: 0;
        top: 0;
    }
    .filters li.item_id_98>ul{
        display: flex;
        flex-flow: wrap;
        flex-direction: column;
        height: 64px;
    }
    .filters li.item_id_98 li{
        width: 135px;
    }
    .filters li.item_id_98 li:nth-child(even){
        margin-bottom: 0;
    }
    .filters .bottom>ul>li>ul { 
        max-height: 180px;
    }
    .filters li.item_id_82{
        display: none;
    }
    .filters ul.filter_slider {
        max-width: 400px;
    }
    .filters .bottom {
        height: calc(100vh - 65px);
        overflow: auto;
    }
    .hot_vacancies .items li {
        flex-direction: column;
    }
    .hot_vacancies .items li .thumbnail img{
        margin: 0 auto;
        display: table;
    }
    .hot_vacancies .button {
        margin: 0 auto;
    }
    .hot_vacancies .items .panel {
        text-align: center;
    }
    .hot_vacancies .price_block p.place_of_work {
        margin: 0 auto 8px auto;
        display: table;
    }
    .form .top {
        flex-direction: column;
        align-items: center;
    }
    .form .left {
        margin-bottom: 30px;
    }
    .blog ul.items {
        grid-gap: 15px;
    }
    .popup_callback {
        width: initial;
        flex-direction: column;
        max-height: 100vh;
        top: 0px;
    }
    .popup_callback .left, 
    .popup_callback .right {
        width: initial;
    }
    .vacancies{
        flex-direction: column-reverse;
    }
    .vacancies ul.items {
        padding-left: 0;
    }
    .vacancy {
        margin-left: 0;
    }
    .post {
        display: flex;
        flex-direction: column;
    }
    .post picture{
        margin-left: auto;
        margin-right: auto;
    }

}

@media (max-width: 991px) {
    .slider .slick-dots{
        display: flex!important;
        justify-content: space-evenly;
    }
    .slider .ant-carousel .slick-dots li {
        width: 100%;
        margin: 0 15px;
    }
    .slider .ant-carousel .slick-dots li button {
        width: 100%;
    }
    .slider .ant-carousel .slick-dots-bottom {
        bottom: 20px;
    }
    footer .menu_bottom {
        grid-template-columns: 1fr 1fr;
    }
    footer .menu_bottom>div{
        margin-bottom: 30px;
    }
    .five_steps ul {
        grid-template-columns: 1fr 1fr;
    }
    .five_steps ul li {
        margin-bottom: 50px;
    }
    .blog ul.items {
        grid-template-columns: 1fr 1fr;
    }
    .vacancy .panel {
        margin-bottom: 50px;
        flex-direction: column;
    }
    .vacancy .panel>div {
        margin-left: 0;
    }
    .additional_services_banners .additional_services ul {
        flex-direction: column;
    }
}


@media (max-width: 767px) {
    #root {
        padding-top: 71px;
    }
    .header .logo p.logo_text_big {
        font-family: "Oswald-600";
        font-size: 21px;
        line-height: 30px;
    }
    .header .logo p {
        font-size: 9px;
        line-height: 18px;
        font-family: "Raleway-600";
    }
    .header .logo {
        align-items: center;
        min-width: 211px;
    }
    .header .phone_menu_buttons img.ico_phone {
        margin-right: 12px;
    }
    .header .phones_dropdown>div {
        flex-direction: column;
        align-items: center;
    }
    .header .phones_dropdown>div {
        padding: 17px 0 20px;
    }
    .header .phones_dropdown.active {
        height: 218px;
    }
    .header ul.phones {
        margin-bottom: 15px;
    }
    .header .city {
        display: flex;
        margin-bottom: 10px;
    }
    .header .city .ant-select {
        margin-top: 0;
    }
    .header .ant-select .ant-select-selection-selected-value {
        color: white;
    }
    .header .ant-select-selection {
        background-color: transparent;
    }
    .header .city p {
        color: white;
    }
    .slider .slid {
        align-items: flex-start;
    }
    .slider .slider_text {
        padding-top: 50px;
    }
    footer .menu_bottom .visa ul {
        grid-template-columns: 1fr;
    }
    footer .copyright {
        align-items: flex-start;
        flex-direction: column;
    }
    .reviews,
    .partners{
        padding-right: 10px;
        padding-left: 10px;
    }
    .hot_vacancies ul.items {
        grid-template-columns: 1fr;
    }
    .form .left {
        display: none;
    }
    .vacancies ul.items li>a {
        flex-direction: column;
        text-align: center;
    }
    .vacancies ul.items li>a picture img{
        margin: 0 auto;
        display: table;
    }
    .vacancies .price_block p.place_of_work{
        margin-left: auto;
        margin-right: auto;
        display: table;
    }
    .vacancy .panel .bottom {
        align-items: flex-start;
        flex-direction: column;
    }
    .content figure.alignleft,
    .content figure.alignright {
        float: initial;
        margin: 0 auto 30px auto;
        display: table;
    }
    .header .top {
        height: 80px;
    }
    .header .ant-select-selector {
        background-color: transparent!important;
    }
    .header .ant-select-selection-item{
        color: white!important;
    }
}

@media (max-width: 575px) {
    h1, h2,
    .post h1,
    .slider .slider_text p.title{
        font-size: 31px;
        line-height: 36px;
    }
    h1, h2{
        margin-bottom: 25px;
    }
    h3{
        font-size: 25px;
        line-height: 29px;
    }
    .slider .slider_text p.slider_big_text{
        font-size: 31px;
        line-height: 36px; 
    }
    .slider .slider_text p.slider_small_text{
        font-size: 21px;
        line-height: 24px;
    }
    .slider .slider_text p.description{
        font-size: 21px;
        line-height: 24px;
    }
    .slider .slider_text {
        padding-top: 30px;
    }
    .job_in_europe ul {
        grid-template-columns: 1fr;
    }
    .box {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    footer .copyright>div {
        align-items: flex-start;
        flex-direction: column;
    }
    footer .copyright ul {
        margin-left: 0;
    }
    footer .copyright>div {
        margin-bottom: 30px;
    }
    footer ul.messengers li:not(:last-child) {
        margin-right: 6px;
    }
    footer iframe {
        width: calc(100% + 30px);
        margin-left: -15px;
        margin-right: -15px;
    }
    footer .buttons {
        flex-direction: column;
        align-items: center;
    }
    footer .buttons .button:not(:first-child) {
        margin-left: initial;
        margin-top: 20px;
    }
    footer .left {
        padding: 40px 0;
    }
    .phones {
        grid-column-gap: 15px;
    }
    .five_steps ul {
        grid-template-columns: 1fr;
    }
    .blog ul.items {
        grid-template-columns: 1fr;
    }
    .blog ul.items li {
        max-width: initial;
    }
    .popup_callback form {
        width: initial;
    }
    figure.is-type-video iframe{
        width: 100%;
    } 
    .comments .list ul li img{

    }
    .content table, .content th, .content td {
        padding: 2px;
        font-size: 12px;
    }
    .comments p.login_with_google {
        text-align: initial;
    }
    .comments .list ul li {
        flex-direction: column;
    }
    .comments .list ul li li{
        margin-left: 25px;
    }
    .comments .list ul li>div {
        margin-left: 0;
    }
    form {
        width: 100%;
    }
    .filters {
        padding: 5px;
    }

}


@media (max-width: 359px) {
    .header .top{
        padding-right: 10px;
        padding-left: 10px;
    }
    .header .phone_menu_buttons img.ico_phone {
        margin-right: 5px;
    }
    .vacancies ul.items li>a picture img {
        width: 100%;
    }
    .button_big {
        width: 100%;
    }
}


